.index {
    display: flex;
    justify-content: center;
    width: 100%;
    filter: drop-shadow(4px 8px 4px rgba(0, 0, 0, 0.5));
}

.contentContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    position: absolute;
    top: 100px;
}

.uniqueContent {
    width: 100%;
    padding: 20px 10px 0px 0px;
}
