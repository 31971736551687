.index {
    display: flex;
    justify-content: center;
    width: 100%;
    filter: drop-shadow(4px 8px 4px rgba(0, 0, 0, 0.5));
}

.contentContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    position: absolute;
    top: 100px;
}

.mainContent {
    width: 75%;
    min-height: 690px;
    padding: 20px 10px 0px 0px;
}

.secondaryContent {
    width: 25%;
    height: 100%;
    padding: 20px 0px 0px 10px;
}

.contentArea {
    position: relative;
    padding: 10px;
}

@media (max-width: 800px) {
    .contentContainer {
        flex-direction: column;
    }

    .mainContent {
        width: 100%;
        padding: 0px;
        padding-top: 10px;
    }

    .secondaryContent {
        width: 100%;
        padding: 0px;
        padding-top: 10px;
    }

    html {
        overflow-y: scroll;
    }
}
