.container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    color: white;
}

.containerHeader {
    height: 40px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.containerHeader h3 {
    padding: 10px;
    font-size: 1.2em;
}
