.ant-card {
    box-shadow: none !important;
    background: transparent;
    border-radius: 0px !important;
}

.messageCard {
    min-height: 70px;
    max-height: 100px;
    overflow-y: auto;
}

.messageCard .ant-card-head {
    border-bottom: 1px solid #6db946;
    padding: 2px;
    color: #06793a;
    min-height: 25px;
}

.messageCard .ant-card-head-title {
    font-size: 1rem;
}

.messageCard .messageCardTime {
    font-size: 0.9rem;
}

.messageCard .messageCardTime .anticon {
    margin-right: 4px;
}

.messageCard .messageCardTime .anticon svg {
    width: 0.9em;
    height: 0.9em;
}

.messageCardTimeIcon {
    margin-right: 5px;
}

.messageCard .ant-card-body {
    padding: 0 4px;
    font-size: 0.9em;
    background-color: transparent;
    word-break: break-all;
}

.messageCard .ant-card-actions {
    padding: 0 4px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #6db946;
    border-radius: 0px;
}
.messageCard .ant-card-actions li {
    margin: 0;
    margin-left: 60%;
}

.messageCard .ant-card-actions img {
    width: 12px;
}
.likesNumber {
    margin-left: 5px;
    font-size: 0.9em;
}
