:root {
    /* --fc-border-color: #6db946; */
    --fc-border-color: #2d5a33;
    --fc-today-bg-color: rgba(45, 90, 51, 0.5);
    /* --fc-button-bg-color: #8a95a5; */
    /* --fc-button-bg-color: #4b3b47; */
}

.dayHeader {
    background-color: rgb(109, 185, 70);
}

.fc .fc-header-toolbar {
    margin-top: 20px;
}

.fc-view-harness,
.fc-view-harness-active {
    border-radius: 8px;
}

.fc .fc-toolbar-title {
    font-size: 1.2em;
    color: #2d5a33;
}

.fc .fc-button {
    font-size: 12px;
}

.fc .fc-dayGridMonth-view tbody {
    color: #2d5a33;
}

.fc .fc-popover-header,
.fc .fc-popover-body {
    background-color: rgba(45, 90, 51, 0.3);
}

.fc .fc-popover-title {
    color: #2d5a33;
}

.fc .fc-popover {
    z-index: 1;
}
