.messageArea {
    width: 100%;
}
.messageAreaInputGroup {
    margin-top: 10px;
}

.messageAreaInputGroup .ant-btn {
    background-color: #6db946;
}

.messageAreaInputGroup .ant-input {
    border-radius: 6px 0px 0px 6px;
}

.messageAreaInputGroup .anticon {
    margin-top: 1px;
    margin-left: -2px;
}

.messageLoadingSpin .ant-spin-dot .ant-spin-dot-item {
    background-color: #2d5a33;
}

.messageLoadingSpin {
    text-align: center;
}
