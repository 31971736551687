:root {
    --fc-event-bg-color: transparent;
    --fc-event-border-color: transparent;
}

.eventDisplay {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding-left: 2px;
    color: white;
    background-color: #7DCFB6;
}

.Evento, .Eventos {
    background-color: #9b51e0 !important;
}

.TI {
    background-color: #8a95a5ff !important;
}

.RH {
    background-color: #f1892d !important;
}

.Embrapii {
    background-color: #3f93ce !important;
}

.GT {
    /* background-color: #222e50 !important; */
    background-color: #c33c54 !important;
}

.Visita, .Visitas {
    background-color: #3f784c !important;
    /* background-color: #cffcff !important; */
}

.Viagem {
    background-color: #ffc43d !important;
}

.Feriados {
    background: #494947 !important;
}

.Aniversario {
    color: black !important;
    background: #FFFF44 !important;
}

.fc .fc-event-main {
    background-color: transparent;
}

.eventDisplay div {
    font-size: 0.7em !important;
    overflow: hidden;
    /* color: ; */
}
