* {
    margin: 0;
    padding: 0;
    font-family: "jost";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}

html {
    height: 100%;
    scroll-behavior: smooth;
    /* overflow-x: hidden; */
}

body {
    width: 100%;
    height: 100vh;
    background: url(../img/bg.jpg) repeat-y;
    background-size: cover;
    background-attachment: fixed;
}

.blur {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2) repeat;
    backdrop-filter: blur(5px);
    background-size: contain;
}

:root {
    -primary: #6db946;
    -secondary: rgba(242, 242, 242, 0.7);
    -tertiary: #3d893d;
    -selected: #2d5a33;
}
