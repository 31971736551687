.bwArea .bwCard {
    padding: 10px;
    border-bottom: 1px solid #6db946;
}

.bwLink {
    text-decoration: none;
}

.bwTitle {
    color: #06793a;
}
