.eventModal .ant-modal-content {
    width: 100%;
    padding: 0;
    border-radius: 16px;
    background-color: #f2f2f2;
    z-index: 1000;
}

.eventModal .ant-modal-title {
    width: 100%;
    height: 100%;
    color: white !important;
}

.eventModal .ant-modal-header {
    width: 100%;
    border-radius: 16px;
    background-color: transparent !important;
}

.eventModal .ant-modal-body {
    padding: 0px 15px;
    color: #303030;
    border-radius: 0px 0px 16px 16px;
    box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.eventModal .ant-modal-close {
    position: absolute;
    top: 10px;
}
.eventModal .ant-modal-close .ant-modal-close-x {
    color: white;
}

.eventCardHeader {
    padding: 10px;
    border-radius: 16px 16px 0px 0px;
    cursor: move;
}

.eventCardHeader h3 {
    font-size: 1em;
    line-height: 15px;
}

.eventCardBodyMainContent {
    /* width: 70%; */
    width: 100%;
    padding-bottom: 10px;
}

.eventCardBodyContent {
    width: 30%;
    padding-bottom: 10px;
}

.eventCardBodyDescription {
    width: 100%;
    height: fit-content;
    margin-top: 4px;
    min-height: 150px;
    background-color: white;
    border-radius: 8px;
}

.eventCardBodyDescription div {
    width: auto;
    height: auto;
    padding: 5px;
    padding-right: 10px;
    font-size: 0.7em;
    word-break: break-all;
}

.eventCardBodyAttendees {
    width: 100%;
    height: fit-content;
    min-height: 150px;
    background-color: white;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .eventCard {
        width: 300px;
        height: 150px;
        top: 15%;
        left: 0%;
    }

    .eventCard .eventCardBody .eventCardBodyMainContent .eventCardBodyDescription {
        overflow-y: scroll;
    }
}
