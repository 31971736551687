.header {
    width: 100%;
    height: 100px;
    position: fixed;
    z-index: 5;
    filter: drop-shadow(4px 8px 4px rgba(0, 0, 0, 0.5));
}

.waves {
    background: url(../../img/wave_header.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.headerContent {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
}

.brandImage {
    margin: 0 auto;
    width: 95px;
    height: 95px;
    background: url(../../img/brand.png);
    background-size: contain;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.headerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    position: relative;
    left: 30px;
    background-color: white;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
}

.dropdownContent {
    display: none;
    width: 150px;
    height: 300px;
    position: absolute;
    top: 65px;
    left: 30px;

    box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border-top-left-radius: 0;
    z-index: 3;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.dropdownContent a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.dropdownContent a:hover {
    background-color: #3d893d;
}

.firstLink {
    border-top-right-radius: 8px;
}
